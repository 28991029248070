import { useState } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import { media } from '@dfds-ui/theme'
import { useQuery, gql } from '@apollo/client'
import { format, parseISO } from 'date-fns'
import { ChevronRight } from '@dfds-ui/icons/system'
import {
  ListItem,
  ListText,
  ListTextGroup,
  ListIcon,
  SelectField,
  Skeleton,
} from '@dfds-ui/react-components'

import { useLocaleContext } from '../LocaleContext'

const NASDAQ_QUERY = gql`
  query NasdaqQuery($locale: String!, $startDate: String!, $endDate: String!) {
    nasdaq(startDate: $startDate, endDate: $endDate, locale: $locale) @client {
      id
      title
      date
    }
  }
`

const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
    return start + i * step
  })
const years = range(new Date().getFullYear(), 2008, -1)

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const langs = ['English', 'Danish']

const AnnouncementResults = ({ locale, year, month }) => {
  const { localeLower } = useLocaleContext()
  let startingDate = ''
  let endingDate = ''

  if (year === 'All years' && month === 'All months') {
    startingDate = `${years[years.length - 1]}-1-1`
  } else if (year === 'All years' && month !== 'All months') {
    startingDate = `${years[years.length - 1]}-${month}-1`
  } else if (year !== 'All years' && month === 'All months') {
    startingDate = `${year}-1-1`
  } else {
    startingDate = `${year}-${month}-1`
  }

  if (year === 'All years' && month === 'All months') {
    endingDate = format(new Date(), 'yyyy-MM-dd')
  } else if (year === 'All years' && month !== 'All months') {
    endingDate = `${new Date().getFullYear()}-${month}-1`
  } else if (year !== 'All years' && month === 'All months') {
    endingDate = `${year}-12-31`
  } else {
    if (month === '2') {
      endingDate = `${year}-${month}-28`
    } else if (month === ('1' || '3' || '5' || '7' || '8' || '10' || '12')) {
      endingDate = `${year}-${month}-31`
    } else {
      endingDate = `${year}-${month}-30`
    }
  }

  const { data, loading, error } = useQuery(NASDAQ_QUERY, {
    variables: {
      locale,
      startDate: startingDate,
      endDate: endingDate,
      offset: 1,
      limit: 1,
    },
  })

  if (loading)
    return Array(5)
      .fill()
      .map((i) => (
        <Skeleton
          key={i}
          variant="rect"
          css={css`
            height: 105px;

            ${media.greaterThan('m')`
              height: 81px;
            `}
          `}
        />
      ))
  if (error || data.nasdaq.length === 0)
    return (
      <ListItem>
        <ListText styledAs="body">
          There are no announcements fitting your selection
        </ListText>
      </ListItem>
    )

  return data.nasdaq.map(({ id, title, date }) => (
    <Link
      key={id}
      to={`/${localeLower}/about/investors/announcements/announcement#lang=${locale}&id=${id}`}
    >
      <ListItem multiline divider>
        <ListTextGroup>
          <ListText styledAs="labelSmall">
            {format(parseISO(date), 'dd MMMM yyyy')}
          </ListText>
          <ListText styledAs="bodyBold">{title}</ListText>
        </ListTextGroup>
        <ListIcon icon={ChevronRight} />
      </ListItem>
    </Link>
  ))
}

const Announcements = () => {
  const [locale, setLocale] = useState('English')
  const [month, setMonth] = useState('All months')
  const [year, setYear] = useState(new Date().getFullYear())

  return (
    <>
      <div
        css={css`
          display: flex;
          width: 100%;
          flex-direction: column;

          ${media.greaterThan('l')`
            flex-direction: row;
          `}
        `}
      >
        <div>
          <SelectField
            onChange={(e) => setLocale(e.target.value)}
            css={css`
              min-width: 100%;

              ${media.greaterThan('m')`
                min-width: 150px;
              `}
            `}
          >
            {langs.map((lang) => (
              <option key={lang}>{lang}</option>
            ))}
          </SelectField>
        </div>
        <div
          css={css`
            display: flex;
            width: 100%;
            flex-direction: column;

            ${media.greaterThan('l')`
              width: auto;
              margin-left: auto;
              flex-direction: row;
            `}
          `}
        >
          <SelectField
            value={year}
            onChange={(e) => setYear(e.target.value)}
            css={css`
              ${media.greaterThan('l')`
                margin-right: 20px;
                min-width: 150px;
              `}
            `}
          >
            <option key="all-years" value="All years">
              All years
            </option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </SelectField>
          <SelectField
            value={month}
            disabled={year === 'All years'}
            onChange={(e) => setMonth(e.target.value)}
            css={css`
              min-width: 150px;
            `}
          >
            <option key="all-months" value="All months">
              All months
            </option>
            {months.map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </SelectField>
        </div>
      </div>
      <div
        css={css`
          margin-bottom: 40px;
        `}
      >
        <AnnouncementResults locale={locale} year={year} month={month} />
      </div>
    </>
  )
}

export default function AnnouncementList() {
  return <Announcements />
}
